// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import "materialize-css"
import "./service_worker_companion"


Rails.start()
Turbolinks.start()
ActiveStorage.start()

function systemMessages() {
  let notifications = document.getElementsByClassName("systemMessage");
  for (let item of notifications) {
    let msgContent = item.innerHTML;
    M.toast({ html: msgContent, displayLength: 2500});
  }
}

function initScrollBarTabs() {
  let target = document.getElementById('categoriesScrollbar');
  if (target != undefined) {
    M.Tabs.init(target, { swipeable: true, onShow: scrollButtonCallback() });
  }
}


const initBottomModal = function() {
  if (document.getElementById('newEntryModal') != null) {
    let target = document.getElementById('newEntryModal');
    let instance = M.Modal.init(target, {});
    let close = document.getElementById('closeEntryModal');
    close.addEventListener('click', function(e) {
      instance.close();
    })
  }
}

const initSettingsModal = function() {
  let target = document.getElementById('newEntryModal');
  if (target != null) {
    M.Modal.init(target, {});
  }
}

const buttonCallback = function(e) {
  let category_id = this.dataset['id'];
  let xhr = new XMLHttpRequest();
  xhr.open("PUT", '/landing/select_category', true);
  let params = JSON.stringify({category_id: category_id})

  xhr.setRequestHeader("Content-type", "application/json; charset=utf-8");

  xhr.onreadystatechange = function () {
    if (this.readyState === XMLHttpRequest.DONE && this.status === 200) {
      // Request finished. Do processing here.
      eval(xhr.response);
    }
  }
  xhr.send(params);
}


const scrollButtonCallback = function() {
  // categoryButton
  if (document.getElementsByClassName('categoryButton')) {
    let targets = document.getElementsByClassName('categoryButton');
    Array.from(targets).forEach(function (target) {
      target.addEventListener('click', buttonCallback, false);
    });
  }
}

function updateOffline(params) {
  
  
  let status = document.getElementById("status");
  let navStatus = navigator.onLine ? "online" : "offline";

  function updateOnlineStatus(event) {

    status.className = navStatus;
    status.innerHTML = navStatus.toUpperCase();
  }
  
  if (navStatus != null) {
    
    let form = document.forms[0];
    let banner = document.getElementById('navStatus');
    let deleteButton = document.querySelectorAll('[data-method="delete"]')[0];

    if (navStatus === 'offline') {
      if (banner != null) {
        banner.classList.remove('hidden');
      }
      if (form != null && deleteButton != null) {
        Array.from(form.elements).forEach(formElement => formElement.disabled = true);
        deleteButton.classList.add('disabled');
      }
      let editLink = document.querySelector('a[href$="edit"]');

      if (editLink != null) {
        editLink.setAttribute("href", "javascript:void(0)" )
      }
    } else {
      if (banner != null) {
        banner.classList.add('hidden');
      }
      if (form != null && deleteButton != null) {
        Array.from(form.elements).forEach(formElement => formElement.disabled = false);
        deleteButton.classList.remove('disabled');
      }

    }
  }
}

// helps you detect mobile browsers (to show a relevant message as the process of installing your PWA changes from browser to browser)
var isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Samsung: function () {
    return navigator.userAgent.match(
      /SAMSUNG|Samsung|SGH-[I|N|T]|GT-[I|N]|SM-[A|N|P|T|Z]|SHV-E|SCH-[I|J|R|S]|SPH-L/i,
    );
  },
  Windows: function () {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

// use this to check if the user is already using your PWA - no need to prompt if in standalone
function isStandalone() {
  const isStandalone = window.matchMedia("(display-mode: standalone)").matches;
  if (document.referrer.startsWith("android-app://")) {
    return true; // Trusted web app
  } else if (navigator.standalone === true || isStandalone) {
    return true;
  }
  return false;
}

function showInstallAppBanner() {
  let bannerHolder = document.getElementById('bannerHolder');
  let navbar = document.getElementById('topNavBar');
  let categoriesScrollBar = document.getElementById('categoriesScrollbar');
  let appContainer = document.getElementById('app');
  let footer = document.getElementsByTagName('footer');

  if (bannerHolder != null) {
    bannerHolder.classList.remove('hidden');
  }
  if (navbar != null) {
    navbar.classList.add('hidden');
  }
  if (categoriesScrollBar != null) {
    categoriesScrollBar.classList.add('hidden');
  }

  if (appContainer != null) {
    appContainer.classList.add('hidden');
  }

  if (footer.length > 0) {
    footer[0].classList.add('hidden');
  }
}

function installAppBanner() {
  if (isStandalone() === false) {
    showInstallAppBanner();
  }
}

function initializeTextAreas() {
  let items = document.getElementsByClassName('materialize-textarea');
  if (items.length > 0) {
    for (let index = 0; index < items.length; index++) {
      let item = items[index];
      M.textareaAutoResize(item);
    }    
  }
}

// Document Ready
document.addEventListener("turbolinks:load", function () {
  console.log("LOADED?RELOADED")
  // # Document ready events
  M.AutoInit();
  M.updateTextFields();
  systemMessages();
  initScrollBarTabs();
  initBottomModal();
  scrollButtonCallback();
  updateOffline();
  initializeTextAreas();
  installAppBanner();
})