if ('serviceWorker' in navigator) {
  window.addEventListener('load', function() {
    navigator.serviceWorker.register('/service-worker.js', { scope: "/" })
      .then(function(registration) {
        console.log("[ServiceWorker Client] registration successful: ", registration.scope);
        if (navigator.onLine === true) {
          registration.update();
        }
      }, function(error) {
        console.log("[ServiceWorker Client] registration failed: ", error);
      });
  });
}